import axios from "axios";

export function getCookieValue(cname: string) {
	let name = cname + "=";
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(";");
	const targetCookie = ca.find((cookie) => cookie.includes(name));
	return targetCookie ? targetCookie.split("=")[1] : "";
}

export const createLeadProperty = (
	property,
	locale: string,
	agencyName: string = "",
	source: string = "",
	pageUri: string = ""
): Promise<any> => {
	return new Promise(async (res, rej) => {
		try {
			const cookie = getCookieValue("hubspotutk");
			const { data } = await axios.post(
				"lead/property",
				{
					...property,
					cookie,
					pageUri,
				},
				{
					params: {
						locale,
						agencyName,
						source,
					},
				}
			);
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};

export const createLeadPropertyPropy = (
	property,
	locale: string,
	agencyName: string = "",
	source: string = "",
	pageUri: string = ""
): Promise<any> => {
	return new Promise(async (res, rej) => {
		try {
			const cookie = getCookieValue("hubspotutk");
			const { data } = await axios.post(
				"lead/property/propy",
				{
					...property,
					cookie,
					pageUri,
				},
				{
					params: {
						locale,
						agencyName,
						source,
					},
				}
			);
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};

export const editLeadProperty = (
	propertyData,
	propertyId,
	locale: string
): Promise<any> => {
	return new Promise(async (res, rej) => {
		try {
			await axios.patch(
				`lead/property/${propertyId}`,
				{
					...propertyData,
				},
				{
					params: {
						locale,
					},
				}
			);
			res("");
		} catch (error) {
			rej(error);
		}
	});
};

export const getMainProperty = (id?: number | null): Promise<any> => {
	return new Promise(async (res, rej) => {
		try {
			const { data: property } = await axios.get("lead/main-property", {
				params: { id },
			});
			res(property);
		} catch (error) {
			rej(error);
		}
	});
};

export const getLeadProperties = (): Promise<any> => {
	return new Promise(async (res, rej) => {
		try {
			const { data: properties } = await axios.get(`lead/properties`);
			if (properties && properties.length > 0) {
				res(properties);
			} else {
				res([]);
			}
		} catch (error) {
			rej(error);
		}
	});
};

export const getEstimationPropertyById = (id: number): Promise<any> => {
	return new Promise(async (res, rej) => {
		try {
			const { data: property } = await axios.get(`estimation-property/${id}`);
			res(property);
		} catch (error) {
			rej(error);
		}
	});
};

export const updateEstimation = (id: number, values: any) => {
	return new Promise<void>(async (res, rej) => {
		try {
			await axios.post(`estimation-property/estimation/update/${id}`, {
				...values,
			});
			res();
		} catch (error) {
			rej(error);
		}
	});
};

export const getLeadPropertiesPagination = (
	agenciesPageNumber: number,
	agenciesPageLimit: number
): Promise<any> => {
	return new Promise(async (res, rej) => {
		try {
			const { data } = await axios.get(`lead/propertiesPagination`, {
				params: { agenciesPageLimit, agenciesPageNumber },
			});

			res(data);
		} catch (error) {
			rej(error);
		}
	});
};

export const getEstimation = (propertyId) => {
	return new Promise(async (res, rej) => {
		try {
			const { data: estimate } = await axios.get(
				`property/${propertyId}/estimation`
			);
			res(estimate);
		} catch (error) {
			rej(error);
		}
	});
};

export const getSimilarProperties = (
	propertyId,
	page = 1,
	limit = 10,
	filter
): Promise<any> => {
	return new Promise(async (res, rej) => {
		try {
			const { data } = await axios.get(
				`general-properties/${propertyId}/similar`,
				{ params: { page, limit, ...(filter && { availability: filter }) } }
			);
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};

export const getAgencyPropertiesByAgencyId = (
	agencyId,
	page = 1,
	limit = 10,
	filter
): Promise<any> => {
	return new Promise(async (res, rej) => {
		try {
			const { data } = await axios.get(
				`general-properties/agency/${agencyId}`,
				{ params: { page, limit, ...(filter && { availability: filter }) } }
			);
			console.log(data);
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};

export const getAgencyPropertyById = (id: number) => {
	return new Promise<any>(async (res, rej) => {
		try {
			const { data: property } = await axios.get(`general-properties/${id}`);
			res(property);
		} catch (error) {
			rej(error);
		}
	});
};

// export const getProperties = (userId): Promise<any[]> => {
// 	return new Promise(async (res, rej) => {
// 		res([]);
// 		// const property = await axios.get()
// 	});
// };
